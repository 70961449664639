.invoice-box {
	max-width: 800px;
	margin: auto;
	padding: 30px;
	border: 1px solid #eee;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	font-size: 16px;
	line-height: 24px;
	// font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
	color: #555;
	.bordered-bottom {
		border: none !important;
		border-bottom: 1px solid #eee;
	}
	.invoice-footer {
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		padding-top: 80px;
	}
}

.invoice-box table {
	width: 100%;
	line-height: inherit;
	text-align: left;
}

.invoice-box table td {
	padding: 5px;
	vertical-align: top;
}

.invoice-box table tr td:nth-child(4) {
	text-align: right;
}
.invoice-box table tr td:nth-child(3) {
	text-align: right;
}

.invoice-box table tr.top table td {
	padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
	font-size: 15px;
	text-align: center;
	// line-height: 45px;
	font-weight: bold;
	color: #333;
}

.invoice-box table tr.information table td {
	padding-bottom: 20px;
}

.invoice-box table tr.heading td {
	background: #eee;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
}

.invoice-box table tr.details td {
	padding-bottom: 20px;
}

.invoice-box table tr.item td {
	border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
	border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
	border-top: 2px solid #eee;
	font-weight: bold;
}

@media only screen and (max-width: 600px) {
	.invoice-box table tr.top table td {
		width: 100%;
		display: block;
		text-align: center;
	}

	.invoice-box table tr.information table td {
		width: 100%;
		display: block;
		text-align: center;
	}
}

/** RTL **/
.invoice-box.rtl {
	direction: rtl;
	// font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
	text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
	text-align: left;
}

.heading,
.item,
.total {
	td:nth-child(2) {
		text-align: right;
	}
}

.information {
	td {
		table {
			tr {
				td {
					padding: 10px;
					&:nth-child(2),
					&:nth-child(4) {
						font-weight: bold;
					}
				}
			}
		}
	}

	.row {
		width: 100%;
		display: flex;
		.label {
			width: 52%;
			font-weight: bold;
			font-size: 14px;
		}
		.value {
			padding-left: 0;
			width: 48%;
			font-weight: bold;
		}
	}
	.no_padding {
		padding-right: 0;
		.row {
			margin-right: 0;
			.value {
				padding-right: 0;
			}
		}
	}
}
