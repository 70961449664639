.table-component {
	table {
		border: none !important;
	}
	.ant-table {
		.ant-table-container {
			&:before {
				box-shadow: none !important;
			}
			.ant-table-content {
				table {
					thead,
					.ant-table-thead {
						tr {
							th {
								border-radius: 0;
								font-size: 12.5px !important;
							}
						}
					}
					tbody,
					.ant-table-tbody {
						tr {
							td {
								font-size: 14px !important;
								color: #000;
							}
						}
					}
				}
				.ant-table-tbody {
					tr {
						td {
							// padding: 30px 8px !important;
						}
					}
				}
			}
		}
	}
}

.ant-table-content {
	table {
		/* width */
		&::-webkit-scrollbar {
			width: 10px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}
