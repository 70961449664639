.bill-receipt {
	max-width: 800px;
	margin: auto;
	padding: 20px;
	padding-right: 18px;
	border: 1px solid #000;
	border-top: 0;
	border-bottom: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	font-size: 16px;
	line-height: 24px;
	// font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
	color: #555;
	.bordered-bottom {
		border: none !important;
		border-bottom: 1px solid #000;
	}
	.invoice-footer {
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		padding-top: 80px;
	}
	.total-section {
		display: flex;
		flex-direction: column;
	}
	.discount-section {
		display: flex;
		flex-direction: column;
		text-align: right;
	}
	.discount-value-section {
		display: flex;
		flex-direction: column;
		text-align: right;
	}
}

.bill-receipt table {
	width: 100%;
	line-height: inherit;
	text-align: left;
	border: 1px solid #000;
}

.bill-receipt table td {
	padding: 0;
	vertical-align: top;
}

.bill-receipt table tr td:nth-child(4) {
	// text-align: right;
}
.bill-receipt table tr td:nth-child(3) {
	// text-align: right;
}

.bill-receipt table tr.top table td {
	padding-bottom: 20px;
}

.bill-receipt table tr.top table td.title {
	font-size: 12px;
	text-align: center;
	// line-height: 45px;
	font-weight: bold;
	color: #333;
}

.bill-receipt table tr.bill-information table td {
	// padding-bottom: 10px;
}

.bill-receipt table tr.heading td {
	background: #000;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
}

.bill-receipt table tr.details td {
	padding-bottom: 20px;
}

.bill-receipt table tr.item td {
	border-bottom: 1px solid #000;
}

.bill-receipt table tr.item.last td {
	border-bottom: none;
}

.bill-receipt table tr.total td:nth-child(2) {
	border-top: 2px solid #000;
	font-weight: bold;
}

@media only screen and (max-width: 600px) {
	.bill-receipt table tr.top table td {
		width: 100%;
		display: block;
		text-align: center;
	}

	.bill-receipt table tr.bill-information table td {
		width: 100%;
		display: block;
		text-align: center;
	}
}

/** RTL **/
.bill-receipt.rtl {
	direction: rtl;
	// font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.bill-receipt.rtl table {
	text-align: right;
}

.bill-receipt.rtl table tr td:nth-child(2) {
	text-align: left;
}

.heading,
.item,
.total {
	td:nth-child(2) {
		text-align: right;
	}
}

.bill-information {
	td {
		table {
			tr {
				td {
					// padding: 10px;
					&:nth-child(2),
					&:nth-child(4) {
						font-weight: bold;
					}
				}
			}
		}
	}

	.row {
		width: 100%;
		display: flex;
		.label {
			width: 52%;
			font-weight: bold;
			font-size: 12px;
		}
		.value {
			padding-left: 0;
			width: 48%;
			font-weight: bold;
			font-size: 12px;
		}
	}
	.no_padding {
		padding-right: 0;
		.row {
			margin-right: 0;
			.value {
				padding-right: 0;
			}
		}
	}
}

.bill-item-table {
	border: 1px solid black;
	border-collapse: collapse;
	th,
	td {
		border: 1px solid black;
		border-collapse: collapse;
		font-size: 12px;
		padding: 5px;
		text-align: center;
	}
}
