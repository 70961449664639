.invoice {
	padding: 20px;
	&_row {
		padding: 10px 0;
		display: flex;
		align-items: center;
	}
}

.search_result_style {
	position: absolute !important;
	width: 100%;
	background: white;
	z-index: 10;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.search_result_container {
	max-height: 400px;
	overflow-y: scroll;
	margin-top: 10px;
	.list-style {
		cursor: pointer;
		margin: 0;
		padding: 20px 10px;
		box-sizing: border-box;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		&:hover {
			background-color: #f0f7fa;
		}
		p {
			margin-bottom: 0;
		}
	}
}

.disabled_input_style {
	color: black !important;
    background-color: white !important;
}
