@import url('https://cdn.syncfusion.com/ej2/material.css');

// $theme-color: #3f8dfa;
// $theme-color-hover: #3a68d3;
$theme-color: #3f8dfa;
$theme-color-hover: #6ebaff;
$dark-theme-color: #3a68d3;

.ant-input:focus,
.ant-input-focused {
	// box-shadow: inset 0 0 0 1px #303030, 0 0 0 1px rgb(255 255 255 / 40%), 0 0 0 4px rgb(31 117 203 / 48%) !important;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
}
.ant-table-wrapper {
	.ant-table-thead {
		.ant-table-cell {
			padding: 10px !important;
			background-color: $theme-color;
			color: white;
		}
	}
	.ant-table-summary {
		.ant-table-cell {
			padding: 6px !important;
			text-align: center;
			&:nth-child(1) {
				text-align: left !important;
			}
		}
	}
}

.text_right {
	text-align: right !important;
}

.ant-layout-sider {
	// flex: 0 0 200px !important;
	// max-width: 200px !important;
	// min-width: 200px !important;
	// width: 200px !important;
}

.ant-drawer-header {
	// position: absolute;
	padding: 16px 24px;
	z-index: 99999;
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
	top: 0;
	left: 0;
	right: 0;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 4px 4px 0 0;
}
.ant-drawer-body {
	padding: 24px;
	// padding-top: 0 !important;
	//    margin-top: 3rem;
	font-size: 14px;
	line-height: 1.5;
	word-wrap: break-word;
}

@mixin scrollbar() {
	&::-webkit-scrollbar {
		width: 14px;
		height: 18px;
	}
	&::-webkit-scrollbar-thumb {
		height: 6px;
		border: 4px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		border-radius: 7px;
		background-color: rgba(0, 0, 0, 0.15);
		box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
	}
	&::-webkit-scrollbar-button {
		width: 0;
		height: 0;
		display: none;
	}
	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}

.ant-layout {
	.ant-layout-sider {
		.ant-layout-sider-children {
			.ant-menu {
				.sub_menu_style {
					.ant-menu-sub {
						padding: 0 10px;
						.ant-menu-item {
							width: 100%;
							margin-bottom: 5px;
							padding-left: 40px !important;
						}
						// height: inherit !important;
						// padding-bottom: 0 !important;
					}
				}
			}
		}
	}
}

.ant-layout {
	.ant-layout-sider {
		box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px !important;
		&.ant-layout-sider-collapsed {
			// min-width: 50px !important;
			// max-width: 60px !important;
			.ant-layout-sider-children {
				background-color: #fff !important;
				.ant-menu {
					border: none !important;
					// height: calc(100vh - 60px) !important;
					// padding-bottom: 30px;
					// overflow-y: auto !important;
					// @include scrollbar();
					.ant-menu-item {
						// display: flex !important;
						// width: 100%;
						// padding: 0 calc(50% - 16px / 2) !important;
						.ant-menu-title-content {
							// transition: all 0s ease-in-out !important;
							// opacity: 0 !important;
						}
					}
				}
			}
			.ant-layout-sider-trigger {
				// min-width: 50px !important;
				// max-width: 50px !important;
				// height: 30px;
				// display: flex;
				// align-items: center;
				// justify-content: center;
				background: #f8f8f8 !important;
				span {
					svg {
						color: #000 !important;
					}
				}
				// border-radius: 10px !important;
			}
		}
		background-color: #fff !important;
		.ant-layout-sider-children {
			background: #fff !important;
			.ant-menu {
				// border: none !important;
				// padding: 0 5px;
				// background: #fff !important;
				// // height: calc(100vh - 60px) !important;
				// padding-bottom: 30px;
				// overflow-y: auto !important;
				// @include scrollbar();
				.ant-menu-submenu-title {
					&:hover {
						background: #e0e0e4 !important;
						color: #303030 !important;
					}
				}
				.ant-menu-item {
					// margin: 10px 0 !important;
					// border-radius: 4px;
					// padding-left: 10px !important;
					// height: 40px !important;
					// width: 100%;
					.ant-menu-title-content,
					svg {
						color: #303030 !important;
						font-size: 13px !important;
					}
					&.ant-menu-item-selected {
						background: $theme-color !important;
						svg,
						span {
							color: #fff !important;
							// color: $theme-color !important;
						}
					}
					&:hover {
						// background: #ededf7 !important;
						// color: #000;
					}
				}
			}
		}
		.ant-layout-sider-trigger {
			border-top: 1px solid #dbdbdb;
			// max-width: 200px !important;
			// min-width: 150px !important;
			// height: 30px;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			background: #f8f8f8 !important;
			// border: none;
			span {
				svg {
					color: #000 !important;
				}
			}
		}
	}
}

.ant-menu-inline-collapsed {
	.ant-menu-item,
	.ant-menu-submenu {
		// align-items: center !important;
		// height: 40px !important;

		// padding-left: 12px !important;

		.ant-menu-submenu-title {
			// transition: none !important;
			// height: auto !important;
			// padding-top: 5px !important;
			// padding-left: 12px !important;

			// padding-left: 0 calc(50% - 16px / 2) !important;
		}
		.ant-menu-title-content {
			display: none !important;
		}
	}
}

.ant-menu-item svg {
	font-size: 14px !important;
}
.ant-menu-item-icon {
	font-size: 14px !important;
}

.ant-page-header {
	background-color: #fff !important;
	color: #000 !important;
	box-shadow: none !important;
	height: 60px !important;
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	// box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
	.ant-dropdown-trigger {
		color: #000 !important;
	}
}

.box_shadow {
	border: none !important;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	&:hover {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}
}

.card_container {
	.card {
		padding: 20px;
		&:nth-child(1) {
			.facility_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(2) {
			.facility_container_card {
				background: radial-gradient(#fbc1cc, #fa99b2);
			}
		}
		&:nth-child(3) {
			.facility_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
		&:nth-child(4) {
			.facility_container_card {
				background: radial-gradient(#60efbc, #58d5c9);
			}
		}
		&:nth-child(5) {
			.facility_container_card {
				background: radial-gradient(#f588d8, #c0a3e5);
			}
		}
		&:nth-child(6) {
			.facility_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(7) {
			.facility_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
	}
	&_card {
		border-radius: 10px;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
		transition: all 0.2s;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 20vh;
		transform: scale(0.95);
		color: #fff;
		font-size: 1.5rem;
		&:hover {
			box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
			transform: scale(1);
		}
	}
}

$primaryGradient1: linear-gradient(135deg, #cce7ff 0%, #027bce 100%);
$primaryGradient2: linear-gradient(135deg, #006393 0%, #0088ca 100%);
$primaryGradient3: linear-gradient(135deg, #071a57 0%, #006393 98.96%);
$secondaryGradient1: linear-gradient(135deg, #d52b1e 0%, #ea6257 98.96%);
$secondaryGradient2: linear-gradient(135deg, #a51105 0%, #d52b1e 98.96%);

.badge {
	background-color: tomato;
	padding: 5px 10px;
	font-size: 12px;
	font-weight: 700;
	border-radius: 100px;
}

.app_container {
	height: 100vh;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	// background-image: url('https://images.pexels.com/photos/2199293/pexels-photo-2199293.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
	.login_card {
		background: rgba(255, 255, 255, 0.6);
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(3.5px);
		-webkit-backdrop-filter: blur(3.5px);
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.18);
	}
}

.bold {
	font-weight: 700;
}

.error {
	border-color: red !important;
	.ant-select-selector {
		border-color: red !important;
	}
}

.edit_icon,
.delete_icon {
	background-color: #3f8dfa;
	color: #fff;
	width: 30px;
	height: 30px;
	border-radius: 2px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	cursor: pointer;
}
.delete_icon {
	background-color: #eb5353;
	margin-right: 0;
}

.space-between {
	display: flex;
	justify-content: space-between !important;
}

.customer-form {
	.ant-drawer-header {
		padding: 0;
	}
}

.ant-form-horizontal {
	&.required_in_right {
		.ant-form-item-required {
			&::after {
				display: inline-block;
				margin-right: 4px;
				color: #ff4d4f;
				font-size: 14px;
				line-height: 1;
				content: '*';
			}
			&::before {
				display: none !important;
			}
		}
	}
}

.ant-input-number {
	.ant-input-number-handler-wrap {
		display: none !important;
	}
}

.textAlignRight {
	text-align: right !important;
	input {
		text-align: right !important;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.link-style {
	color: skyblue;
	text-decoration: underline;
}

.inwords {
	text-transform: capitalize;
}

.hideFormLabel {
	// display: none;
	.ant-form-item-label {
		display: none !important;
	}
}
