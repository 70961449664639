.new_invoice_container {
	padding: 20px;
	padding-bottom: 120px;
	.header {
		display: flex;
		align-items: center;
		.icon {
			font-size: 25px;
		}
		.title {
			font-size: 20px;
			padding-left: 10px;
		}
	}
	.ele_padding {
		padding: 20px 0;
	}
	td {
		input {
			border: none !important;
		}
	}
	.ant-input-number {
		// border: none !important;
	}
	.footer {
		position: fixed;
		bottom: 0;
		margin: 0 0 0 -20px;
		box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
		background-color: #fff;
		z-index: 3;
		width: 100%;
		height: 80px;
		display: flex;
		align-items: center;
		padding: 20px;
	}
}
