.add-unit {
	.add-unit-form {
		background-color: #f5f5f5;
		border: 1px solid #e3e3e3;
		border-radius: 2px;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
		padding: 5px 20px;
		margin-bottom: 10px;
	}
	.add-table {
		.ant-table-thead > tr > th,
		.ant-table-tbody > tr > .ant-table-row .ant-table tfoot > tr > th,
		.ant-table tfoot > tr > .ant-table-row {
			padding: 8px 8px 8px 8px !important;
		}
		.ant-table-thead {
			tr {
				th {
					border: 1px solid #f5f4f8 !important;
					background-color: #fff !important;
					color: #757383 !important;
					&:nth-child(2){
						background-color: #fff;
						color: #fff !important;
					}
					&:first-child {
						border-top-left-radius: 4px !important;
					}
					&:nth-child(1) {
						border-left: 0 !important;
					}
					&:nth-child(2) {
						border-top-right-radius: 4px !important;
					}
				}
			}
		}
		.ant-table-tbody {
			tr {
				&:hover {
					td {
						.show-on-hover {
							display: flex;
						}
					}
				}
				&:last-of-type {
					td {
						&:first-child {
							border-bottom-left-radius: 4px !important;
						}
						&:nth-child(2) {
							border-bottom-right-radius: 4px !important;
						}
					}
				}
				td {
					border: 1px solid #f5f4f8 !important;
					border-top: 0 !important;
					&:nth-child(4),
					&:nth-child(5) {
						background-color: #fff;
						color: #fff !important;
						border: 0 !important;
					}
					&:first-child {
						border-top-left-radius: 4px !important;
					}
					&:nth-child(1) {
						border-left: 0 !important;
					}
					&:nth-child(2) {
						border-top-right-radius: 4px !important;
					}
					.show-on-hover {
						display: none;
						font-size: 12px !important;
						color: #3a68d3;
						cursor: pointer;
					}
					.delete {
						color: #fd4142;
					}
					&:nth-child(4),
					&:nth-child(5) {
						background-color: #fff;
						border: 0 !important;
					}
					input {
						border-color: white;
						border-radius: 4px;
						box-shadow: none !important;
						outline: none;
						&:hover {
							border-color: #3a68d3;
						}
					}
				}
			}
		}
	}

	.add-button {
		margin-top: 20px;
		font-size: 12px;
		display: flex;
		align-items: center;
		cursor: pointer;
		svg {
			width: 15px;
			height: 15px;
			margin-right: 5px;
			color: #3a68d3;
		}
	}
}
