.login_container {
	background-image: url('../../../public/assets/background.png');
	background-size: auto 100%;
	.login_area {
		display: block;
		background-color: #fff;
		box-shadow: 0px 2px 30px #ccc6;
		min-height: max-content;
		border-radius: 8px;
		.left_area {
			padding: 40px;
			.logo_class {
				padding: 0;
				justify-content: flex-start;
			}
			.ant-input-affix-wrapper {
				padding-left: 0;
				padding-top: 0;
				padding-bottom: 0;
			}
			.ant-input, button {
				height: 44px;
				box-sizing: border-box;
				border-radius: 2px;
				text-indent: 12px;
				font-size: 16px;
			}
			.ant-tabs  {
				.ant-tabs-nav-wrap {
					.ant-tabs-tab {
						.ant-tabs-tab-btn {
							font-size: 16px;	
						}
					}
				}
			}
		}
		.right_area {
			padding: 40px;
			.slick-list {
				.slick-slide {
					.slider_item {
						display: flex !important;
						flex-direction: column;
						align-items: center;
						img {
							width: 300px;
							object-fit: contain;
							align-self: center;
						}
						.title {
							font-size: 16px;
							font-weight: 600;
							margin: 20px 0;
							text-align: center;
						}
						.para {
							text-align: center;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
	.login_image {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 30px;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.nav-link {
		font-weight: bold;
		text-decoration: underline;
		color: #25b394;
	}
}
