h1 {
	font-family: 'Roboto';
	text-align: center;
	margin-bottom: 40px;
}

.timer-wrapper {
	display: flex;
	justify-content: center;
}

.time-wrapper {
	position: relative;
	width: 40px;
	height: 30px;
	font-size: 10px;
	/* font-family: 'Montserrat';
	font-family: 'Inter', sans-serif; */
	font-family: 'Open Sans', sans-serif;
}

.time-wrapper .time {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(0);
	opacity: 1;
	transition: all 0.2s;
}

.time-wrapper .time.up {
	opacity: 0;
	transform: translateY(-100%);
}

.time-wrapper .time.down {
	opacity: 0;
	transform: translateY(100%);
}
